@use '../../Assets/Styles/global_colors' as glob;

.clickable {

}

.btn {
    margin: 2px;
}

.clickable:hover {
    cursor: pointer;
}

.btn-semi-dark {
    background-color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-SEMI-DARK;
    color: glob.$COLOR-LIGHT;
}

.btn-semi-dark:hover {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}
.btn-semi-dark:active {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-semi-dark-composite {
    background-color: glob.$COLOR-SEMI-DARK-COMPOSITE;
    border-color: glob.$COLOR-SEMI-DARK-COMPOSITE;
    color: glob.$COLOR-LIGHT;
}

.btn-semi-dark-composite:hover {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-semi-dark-composite:active {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-light-custom {
    background-color: glob.$COLOR-LIGHT;
    color: glob.$COLOR-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-light-custom:hover {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-light-blue {
    background-color: glob.$COLOR-LIGHT-BLUE;
    color: glob.$COLOR-WHITE;
    border-color: glob.$COLOR-LIGHT-BLUE;
}

.btn-light-blue:hover {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

.btn-light-blue-composite {
    background-color: glob.$COLOR-LIGHT-BLUE-COMPOSITE;
    color: glob.$COLOR-WHITE;
    border-color: glob.$COLOR-LIGHT-BLUE-COMPOSITE;
}

.btn-light-blue-composite:hover {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}
.btn-light-blue-composite:active {
    background-color: glob.$COLOR-WHITE;
    color: glob.$COLOR-SEMI-DARK;
    border-color: glob.$COLOR-LIGHT;
}

