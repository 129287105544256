$names : "notification", "final-version", "start-conference", "deadline";  

@each $name in $names {
    .#{$name}-icon-legend {
        background-image: url(../../Assets/svg/#{$name}-icon.svg);
        background-repeat: no-repeat;
        width: auto;
        height: 24px;
    }
}

.deadline-icon-legend-red {
    background-image: url(../../Assets/svg/deadline-icon-red.svg);
    background-repeat: no-repeat;
    width: auto;
    height: 24px;
}

.deadline-icon-legend-grey {
    background-image: url(../../Assets/svg/deadline-icon.svg);
    background-repeat: no-repeat;
    width: auto;
    height: 24px;
    opacity: 30%;
}
