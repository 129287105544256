@use '../Assets/Styles/global_colors' as glob;

.text-light-blue {
    color: glob.$COLOR-LIGHT-BLUE;
}

.logo {
    height: 4em;
}

.children {
    margin-bottom: 10px;
    min-height: 70vh;
}

a.title {
    color: glob.$COLOR-DARK;
    text-decoration: none;
}

a.title:hover {
    color: glob.$COLOR-DARK;
    text-decoration: none;
}

a {
    color: glob.$COLOR-LIGHT-BLUE;
}

a:hover {
    color: glob.$COLOR-LIGHT-BLUE;
}