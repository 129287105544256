@use '../../../Assets/Styles/global_colors' as glob;

.search-button {
    background-color: #1895C2;
    color: #FFFFFF;
}

// .search-button:active {
//     background-color: #FFFFFF;
//     color: #1895C2;
// }

.search-button:hover {
    background-color: #FFFFFF;
    border-color: #eeeeee;
    cursor: pointer;
    color: glob.$COLOR-DARK;
    
}


