@use "../../Assets/Styles/global_colors" as glob;

.tooltip-box {
    position: relative;
    // display: inline-block;
    width: auto;
    // border-bottom: 1px dotted black;
  }

.relative-inline {
    position: relative !important;
    display: inline !important;
  }
    
.tooltip-box .tooltiptext-hidden {
visibility: hidden;
width: max(120px, 100%);
background-color: glob.$COLOR-WHITE;
color: glob.$COLOR-DARK;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
z-index: 1;
bottom: max(110%, 10px);
min-width: 15em;
left: 50%;
margin-left: -60px;
}

.tooltip-box:hover .tooltiptext-hidden {
    visibility: visible;
}

.tooltip-box:hover {
  z-index: 1;
}

.left-12 {
  transform: translate(-12px);
}

.left-25pct {
  transform: translate(-25%);
}

.left-50pct {
  transform: translate(-50%);
}

.lower-5px {
  transform: translate(0, 5px);
}

.text-blue {
  color: glob.$COLOR-LIGHT-BLUE !important;
}