@use '../../../Assets/Styles/global_colors' as glob;

.text-break-all {
    overflow-wrap: break-word;
}

.current-time-marker {
    // box-sizing: border-box;
    width: 3px;
    background-color: glob.$COLOR-LIGHT-BLUE-COMPOSITE;
    height: 100%;
    position: absolute;
}


$widths: 1em, 2em, 3em, 4em, 5em, 6em, 8em, 10em, 15em, 18em, 20em, 25em, 30em, 35em, 40em, 45em, 50em, 100em, 115em, 150em, 
1px, 5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 100px, 115px, 150px;

@each $width in $widths {
  .max-width-#{$width} {
    max-width: $width;
  }

  .min-width-#{$width} {
    min-width: $width;
  }
}

@for $i from 1 through 366 {
  .offset-day-#{$i} {
    // Need to also add relative or absolute positioning!
    left: $i * 0.2732240437%;
    width: 3px;
  }
}

.dates {
    min-width: 732px;
}

.grey-out {
  opacity : 0.3;
}