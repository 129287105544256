$names : "notification", "final-version", "start-conference", "deadline";  

@each $name in $names {
    .#{$name}-icon {
        background-image: url(../../Assets/svg/#{$name}-icon.svg);
        background-position: left;
        background-repeat: no-repeat;
        position: relative;
        width: 25px;
        height: 25px;
        // transform: translate(-12px, 0px);
    }
}

@each $name in $names {
    .#{$name}-icon-out-of-date {
        position: relative;
        background-image: url(../../Assets/svg/#{$name}-icon-red.svg);
        background-position: left;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        // transform: translate(-12px, 0px);
    }
}

.empty-icon {
    width: 30px;
    height: 24px;
    transform: translate(-12px, 0px);
}

.web-icon {
    background-image: url(../../Assets/svg/web-icon.svg);
    background-position: left;
    background-repeat: no-repeat;
    width: 30px;
    height: 24px;
}
